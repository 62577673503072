import React, { useState } from 'react'
import Layout from '../components/layout'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Button from '../components/button'

const SuccessMsg = ({ name, clearAll }) => {
  return (
    <div className="bg-white flex items-center justify-center -mt-12 py-32">
      <div>
        <h3 className="text-gray-33 text-xl md:text-2xl pr-10 border-b border-gray-99 pb-2 inline-block mb-4">
          預約成功
        </h3>
        <p className="text-lg leading-8 text-gray-33">
          {name} 您好，感謝您的預約，如有最新消息將盡速以E-mail通知您。
          <br />
          若您有其他疑問，請撥打 04 25337888 將有專人為您服務。
        </p>
        <div className="text-center mt-12">
          <Button
            Padding="py-1"
            className={`cursor-pointer px-4 py-1 tracking-widest`}
            onClick={() => {
              clearAll()
            }}
          >
            確認
          </Button>
        </div>
      </div>
    </div>
  )
}

const SubscribeForm = ({ result, setResult }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [sending, setSending] = useState(false)

  const onChangeHanlder = (event, callBack = () => {}) => {
    const target = event.target
    callBack(target.value)
  }

  const clearAll = () => {
    setName('')
    setPhone('')
    setEmail('')
    setResult(null)
  }

  const _handleSubmit = (e) => {
    e.preventDefault()
    setSending(true)
    setResult(null)
    addToMailchimp(email, {
      NAME: name,
      PHONE: phone,
    }) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data)
        setResult(data)
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
      .then(() => {
        setSending(false)
      })
  }
  return (
    <div className="relative">
      {result && result.result === 'success' ? (
        <SuccessMsg name={name} clearAll={clearAll} />
      ) : (
        <div>
          <div className="mx-auto w-350 max-w-full">
            <h4>您的聯絡資訊</h4>
            <form
              onSubmit={(e) => {
                _handleSubmit(e)
              }}
            >
              <div className="mt-8">
                <label className="text-gray-4d font-light">*Name 姓名</label>
                <input
                  className="border-b border-gray-4d block w-full transition-all duration-500 outline-none px-2 pb-1 focus:mt-2 focus:outline-none"
                  value={name}
                  onChange={(e) => {
                    onChangeHanlder(e, setName)
                  }}
                  required
                />
              </div>
              <div className="mt-8">
                <label className="text-gray-4d font-light">*Phone 電話</label>
                <input
                  className="border-b border-gray-4d block w-full transition-all duration-500 outline-none px-2 pb-1 focus:mt-2 focus:outline-none"
                  value={phone}
                  onChange={(e) => {
                    onChangeHanlder(e, setPhone)
                  }}
                  required
                />
              </div>
              <div className="mt-8">
                <label className="text-gray-4d font-light">*Email</label>
                <input
                  className="border-b border-gray-4d block w-full transition-all duration-500 outline-none px-2 pb-1 focus:mt-2 focus:outline-none"
                  value={email}
                  onChange={(e) => {
                    onChangeHanlder(e, setEmail)
                  }}
                  type="email"
                  required
                />
              </div>
              <p className="font-light text-require mt-2">*處為必填欄位</p>
              <div className="text-center mt-12">
                {result && result.result === 'error' ? (
                  <p
                    className="text-require text-sm mb-10"
                    dangerouslySetInnerHTML={{
                      __html:
                        result.msg.search('is_subscribed') >= 0
                          ? '這個帳號已經訂閱過囉～'
                          : result.msg,
                    }}
                  ></p>
                ) : null}
                {/*<pre>*/}
                {/*  {JSON.stringify(result,null,4)}*/}
                {/*</pre>*/}
                <Button
                  Tag="button"
                  Padding="py-1"
                  className={`cursor-pointer px-4 py-1 tracking-widest ${
                    sending ? 'pointer-events-none' : null
                  }`}
                  type="submit"
                >
                  {sending ? '傳送中...' : '確認'}
                </Button>
              </div>
            </form>
          </div>
          <p className="text-left xl:text-center text-sm md:text-base mt-14 font-light text-gray-4d leading-7">
            RYZEN重視訪客的隱私權，因此所有相關資訊將嚴格保密。
            <br />
            透過與您互動、使用及體驗相關的資料收集，亦或是您直接提供之資訊，均為保護內容。
            <br />
            取得之資料僅供RYZEN於其內部、原說明的使用目的和範圍使用，不會將資料提供給第
            三方或移作其他目的，也請訪客妥善保密個人資料。
          </p>
        </div>
      )}
    </div>
  )
}

export default () => {
  const [result, setResult] = useState(null)
  let name = null

  return (
    <Layout>
      <div className="px-6 mx-auto py-8 md:min-h-full-content md:px-20 md:pt-12 md:pb-12">
        <h1 className="uppercase font-light tracking-widest text-gray-33 text-20 md:text-36">
          REGISTER
        </h1>
        <div className="mt-12 md:flex">
          <div className="md:w-1/3">
            {result && result.result === 'success' ? null : (
              <div className="max-w-370">
                <p className="text-xl leading-8">
                  弘文中學首排 / 坪數:37-42(純三房)
                </p>
                <hr className="border-gray-99 my-4"/>
                <p className="text-xl leading-8">
                  感謝您的預約，以獲取相關資訊。
                  <br />
                  如有最新消息，我們將會派專人盡速聯絡您!
                </p>
              </div>
            )}
          </div>
          <div className="mt-12 md:mt-0 md:w-1/3">
            <SubscribeForm name={name} result={result} setResult={setResult} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
